<template>
	<NuxtLayout>
		<NuxtPage v-if="!isScannerOpened" />
		<Alert v-for="alert in alertsStore.getAlerts" :key="alert.id" :title="alert.title" :variant="alert.variant" />
		<QRCodeScanner v-if="isScannerOpened" />
	</NuxtLayout>
</template>
<script setup lang="ts">
const ALERT_DURATION_IN_MS = 3000
const siteConfig = useSiteConfig()
const alertsStore = useAlertsStore()
const qrCodeScanner = useQRCodeScanner()
const { alerts } = storeToRefs(alertsStore)
const { isScannerOpened } = storeToRefs(qrCodeScanner)
const router = useRouter()
const route = useRoute()
watch(
	alerts,
	() => {
		if (!alerts.value.length) return

		setTimeout(() => {
			if (!alerts.value.length) return
			alertsStore.removeAlert(alerts.value[0].id)
		}, ALERT_DURATION_IN_MS)
	},
	{ deep: true }
)

onMounted(async () => {
	const { Capacitor } = await import('@capacitor/core')
	const { App } = await import('@capacitor/app')
	if (Capacitor.isNativePlatform()) {
		App.addListener('appUrlOpen', function (event: any) {
			const slug = event.url.split('.com.br').pop()

			if (slug) {
				router.push({
					path: slug,
				})
			}
		})

		App.addListener('backButton', data => {
			if (
				// @ts-ignore
				(typeof navigation !== 'undefined' && navigation.canGoBack) ||
				(typeof window !== 'undefined' && window.history.length > 3)
			) {
				router.back()
			} else {
				App.exitApp()
			}
		})
	}
})

useSeoMeta({
	title: siteConfig.name,
	ogTitle: siteConfig.name,
	description: siteConfig.description,
	ogDescription: siteConfig.description,
	ogImage: 'https://cdn.iloyal.com.br/assets/images/og-image.png',
	twitterCard: 'summary_large_image',
	robots: siteConfig.indexable ? 'index, follow' : 'noindex, nofollow',
})
</script>
